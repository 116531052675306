import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServicesOne from '../components/Services/ServicesOne'
import OurServices from "../components/DataScienceAndMLCompany/OurServices"

import StartProject from '../components/Common/StartProject'

const Services = (props) => {
    let data = props.data.wpgraphql;

    
    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language} />
            <PageBanner
                pageTitle={data.page.servicesPageAcf.title} 
                homePageText={props.pageContext.language ==="EN" ? "Home" : (props.pageContext.language ==="FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")} 
                activePageText={data.page.servicesPageAcf.title} 
            />
            {/* <ServicesOne 
                services={data.serviceCategories.edges[1]}
            /> */}
            <OurServices 
                servicesInfo={{ title: data.page.servicesPageAcf.title, subtitle: "test", content:data.page.servicesPageAcf.introductionParagraph }}
                services={data.services}
                from={"services"}
            />
            <StartProject 
                data={ data.page.servicesPageAcf.promoSection }
            />
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf}/>
        </Layout>
    );
}

export default Services

export const pageQuery = graphql`
    query GET_CASE_WEB_SOLUTIONS($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $footerTemplateUri: ID! ) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    servicesPageAcf{
                    title
                    introductionParagraph
                    promoSection {
                        title
                        content
                        cta
                        ctaUrl
                    }
                }
                content
            }
            services (first: 100, where: {language: $language, orderby: {field: MENU_ORDER, order: ASC}}){
                edges {
                    node {
                        uri
                        title
                        excerpt
                        servicesAcf{
                            titleExcerpt
                            smallIcon{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp {
                                        fixed(width: 250, quality: 100){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`